:root {
  --width-sideBar: 18rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; /* inherit box-sizing */
}

html {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

.nav-item > .nav-link {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  /*   svg {
    margin-right: 0.6rem;
  } */
}

p {
  margin-bottom: 0;
}

.loader {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.scrollElement::-webkit-scrollbar,
.optionContainer::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollElement::-webkit-scrollbar-track,
.optionContainer::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
  border-radius: 20px; /* roundness of the tracking area */
}

.scrollElement::-webkit-scrollbar-thumb,
.optionContainer::-webkit-scrollbar-thumb {
  background-color: #d8d8d8; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.chip {
  white-space: normal;
}
